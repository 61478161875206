<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>发货列表</span>
        <el-button
          type="success"
          icon="el-icon-plus"
          @click="add"
          v-if="$check(51)"
          style="float: right; position: relative; bottom: 10px"
          >添加</el-button
        >
        <el-button
          type="primary"
          @click="query(1)"
          style="float: right; margin-right: 10px; position: relative; bottom: 10px"
          icon="el-icon-search"
          >查询</el-button
        >
        <el-input
          v-model="filterData.search"
          placeholder="输入关键字搜索"
          clearable
          style="
            float: right;
            width: 250px;
            margin-right: 10px;
            position: relative;
            bottom: 10px;
          "
        ></el-input>
      </div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="express_name" label="快递名称"> </el-table-column>
        <el-table-column prop="express_no" label="快递单号"></el-table-column>
        <el-table-column prop="express_time" label="发货时间"></el-table-column>
        <el-table-column prop="express_time" label="发货时间">
          <template slot-scope="scope">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in scope.row.data"
                :key="index"
                :timestamp="activity.time"
              >
                {{ activity.context }}
              </el-timeline-item>
            </el-timeline>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="small" type="danger" @click="remove(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" style="text-align: center; margin-top: 10px">
          <el-pagination
            background
            layout="total,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      form: {},
    };
  },

  created() {
    this.query();
  },

  methods: {
    pageChange(page) {
      this.filterData.page = page;
      this.query();
    },
    query(page) {
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/order_express/list", this.filterData)
        .then((res) => {
          if (res.status == 200) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
    remove(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/order_express/remove", { id: id }).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/order_express/save", this.form).then((res) => {
            if (res.status == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form = {};
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
